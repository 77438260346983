/**
 * Global Config
 */

const config = {
  domains: {
    // dev
    // api: "https://fypiqiyua9.execute-api.us-east-2.amazonaws.com",
    // prod
    api: "https://ntak0t19ca.execute-api.us-east-2.amazonaws.com",
  },
};

export default config;
